
































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import EventUserDropdown from '@/partials/forms/Events/EventUserDropdown.vue'
import ActivityModulesDropdown from '@/partials/forms/Events/Activities/ModulesDropdown.vue'
import EventRoomsDropdown from '@/partials/forms/Events/EventRoomsDropdown.vue'
import DateTimeInput from '@/partials/forms/components/DateTime/DateTime.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'
import ProfileUploader from '@/partials/components/ProfileUploader.vue'
import TopicsComboBox from '@/partials/forms/TopicsComboBox.vue'

@Component({
  directives: { mask },
  components: {
    ActivityModulesDropdown,
    EventRoomsDropdown,
    TopicsComboBox,
    ProfileUploader,
    RichTextEditor,
    DateTimeInput,
    EventUserDropdown,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EventsForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) activityId!: string
  @Prop({ type: String }) type!: 'create' | 'edit'
  @Prop() activity!: Record<string, any>

  done = true
  profilePictureURL = ''
  pictureId = ''
  form: Record<string, any> = {
    title: '',
    local: '',
    abstract: '',
    body: '',
    availableOn: moment().format('YYYY-MM-DD HH:mm:ss'),
    availableUp: moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
    vacancies: '',
    type: 'lecture',
    eventId: '',
    eventUserId: '',
    eventActivityModuleId: '',
    eventRoomId: '',
    topics: [],
    confirm: '',
    errors: {
      title: '',
      abstract: '',
      body: '',
      availableOn: '',
      availableUp: '',
      vacancies: '',
      type: '',
      eventId: '',
      eventUserId: '',
      eventActivityModuleId: '',
      eventRoomId: '',
      topics: '',
      confirm: ''
    }
  }

  topics: Array<Topic> = []
  activityTypes = [
    {
      name: 'opening',
      prettyName: 'Abertura'
    },
    {
      name: 'assembly',
      prettyName: 'Assembleia'
    },
    {
      name: 'conference',
      prettyName: 'Conferência'
    },
    {
      name: 'conferenceintl',
      prettyName: 'Conferência Internacional'
    },
    {
      name: 'exam',
      prettyName: 'Exame de Admissão'
    },
    {
      name: 'roundtable',
      prettyName: 'Mesa Redonda'
    },
    {
      name: 'roundtablemodern',
      prettyName: 'Mesa-redonda Moderna'
    },
    {
      name: 'roundtableconventional',
      prettyName: 'Mesa-redonda Convencional'
    },
    {
      name: 'symposium',
      prettyName: 'Simpósio Corporativo'
    },
    {
      name: 'lecture',
      prettyName: 'Palestra'
    },
    {
      name: 'roomcoordinator',
      prettyName: 'Coordenadores de Sala'
    },
    {
      name: 'coordinator',
      prettyName: 'Coordenador'
    },
    {
      name: 'tablepresident',
      prettyName: 'Presidente de Mesa'
    },
    {
      name: 'freepaper',
      prettyName: 'Tema Livre'
    },
    {
      name: 'closing',
      prettyName: 'Encerramento'
    },
    {
      name: 'closingaward',
      prettyName: 'Encerramento com Premiação'
    },
    {
      name: 'award',
      prettyName: 'Premiação'
    },
    {
      name: 'interval',
      prettyName: 'Intervalo'
    },
    {
      name: 'workshop',
      prettyName: 'Oficina'
    },
    {
      name: 'class',
      prettyName: 'Aula'
    },
    {
      name: 'other',
      prettyName: 'Outros'
    }
  ]

  speakerId = ''
  created () {
    this.speakerId = this.$route.params.speakerId ?? ''

    console.log(this.speakerId)

    this.form.eventId = this.eventId
    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        title: 'Minha Programação ABC',
        abstract: 'Lorem Ipsum',
        local: 'Auditorio 3',
        body: `
          <h1>Título Teste</h1>
          <p>Esse é um paragrafo de teste</p>
          <ul>
            <li><strong>Lista 1</strong></li>
            <li><s>Lista 2</s></li>
            <li>Lista 3</li>
          </ul>
          <blockquote>Isso é uma citação</blockquote>
        `,
        availableOn: moment().format('YYYY-MM-DD HH:mm:ss'),
        availableUp: moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        vacancies: '20',
        type: 'lecture',
        eventUserId: this.speakerId
      })
    }

    if (this.type === 'edit') {
      Object.keys(this.activity).forEach((key: string) => {
        this.form[key] = key in this.form ? this.activity[key] : this.form[key]

        if (this.activity.picture) {
          this.profilePictureURL = this.activity.picture.publicPath
          this.pictureId = this.activity.picture.id
        }
      })
      this.form.topics = this.activity.topics.map((topic: Topic) => topic.id)
      this.topics = this.activity.topics
    }
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      abstract: '',
      body: '',
      availableOn: '',
      availableUp: '',
      vacancies: '',
      type: '',
      eventId: '',
      eventUserId: '',
      confirm: ''
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit' ? `/event/${this.eventId}/activity/${this.activityId}` : `/event/${this.eventId}/activity`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess(this.form.title)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess (activityName: string) {
    const successVerb = this.type === 'edit' ? 'editada' : 'cadastrada'

    const flashMessage = {
      message: `A Programação ${activityName} foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    if (this.$route.params.speakerId) {
      this.$router.push({ name: 'EventSpeakers', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
    } else {
      this.$router.push({ name: 'EventActivities', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
    }
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
