var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'edit')?_c('div',{staticClass:"flex justify-center items-center"},[_c('ProfileUploader',{attrs:{"img-url":_vm.profilePictureURL,"owner-id":_vm.$route.params.id,"picture-id":_vm.pictureId,"type":"event-activity"},on:{"image-loaded":function($event){_vm.form.picture = $event},"deleted":function($event){_vm.profilePictureURL = ''; _vm.pictureId = ''}}})],1):_vm._e(),_c('ValidationObserver',{staticClass:"py-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',[_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-select',{attrs:{"label":"Tipo"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.activityTypes),function(activityType){return _c('cv-select-option',{key:activityType.name,attrs:{"value":activityType.name,"invalid-message":_vm.form.errors.type || errors[0]}},[_vm._v(" "+_vm._s(activityType.prettyName)+" ")])}),1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-9",attrs:{"name":"speaker","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('EventUserDropdown',{attrs:{"type":"speaker","event-id":_vm.eventId,"invalid-message":_vm.form.errors.eventUserId || errors[0],"disabled":!!_vm.speakerId},model:{value:(_vm.form.eventUserId),callback:function ($$v) {_vm.$set(_vm.form, "eventUserId", $$v)},expression:"form.eventUserId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-8",attrs:{"name":"title","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Título","invalid-message":_vm.form.errors.title || errors[0]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-4",attrs:{"name":"local","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('EventRoomsDropdown',{attrs:{"event-id":_vm.eventId,"invalid-message":_vm.form.errors.eventRoomId || errors[0]},model:{value:(_vm.form.eventRoomId),callback:function ($$v) {_vm.$set(_vm.form, "eventRoomId", $$v)},expression:"form.eventRoomId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-4",attrs:{"name":"topics","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TopicsComboBox',{attrs:{"request-url":("/event/" + _vm.eventId + "/topic"),"form-topics":_vm.topics,"type":"event"},model:{value:(_vm.form.topics),callback:function ($$v) {_vm.$set(_vm.form, "topics", $$v)},expression:"form.topics"}}),(errors.length )?_c('div',{staticClass:"mb-3 text-red-600"},[_vm._v(" Selecione pelo menos 1 tópico ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-8",attrs:{"name":"module","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ActivityModulesDropdown',{attrs:{"event-id":_vm.eventId,"invalid-message":_vm.form.errors.eventActivityModuleId || errors[0]},model:{value:(_vm.form.eventActivityModuleId),callback:function ($$v) {_vm.$set(_vm.form, "eventActivityModuleId", $$v)},expression:"form.eventActivityModuleId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"name":"abstract","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-area',{attrs:{"label":"Resumo","invalid-message":_vm.form.errors.abstract || errors[0],"rows":5},model:{value:(_vm.form.abstract),callback:function ($$v) {_vm.$set(_vm.form, "abstract", $$v)},expression:"form.abstract"}})]}}],null,true)}),_c('div',{staticClass:"lg:col-span-12"},[_c('RichTextEditor',{model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1),_c('div',{staticClass:"lg:col-span-6"},[_c('DateTimeInput',{attrs:{"label":"Data de Início"},model:{value:(_vm.form.availableOn),callback:function ($$v) {_vm.$set(_vm.form, "availableOn", $$v)},expression:"form.availableOn"}})],1),_c('div',{staticClass:"lg:col-span-6"},[_c('DateTimeInput',{attrs:{"label":"Data de Término","min-date-time":_vm.form.availableOn},model:{value:(_vm.form.availableUp),callback:function ($$v) {_vm.$set(_vm.form, "availableUp", $$v)},expression:"form.availableUp"}})],1),(_vm.type === 'create')?_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","name":"vacancies","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-number-input',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"label":"Vagas","invalid-message":_vm.form.errors.vacancies || errors[0],"type":"number","min":"0","value":0},model:{value:(_vm.form.vacancies),callback:function ($$v) {_vm.$set(_vm.form, "vacancies", $$v)},expression:"form.vacancies"}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"w-full lg:w-1/3"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }